import { useState } from "react";
import { useSelector } from "react-redux";

import "./globalleader.scss";

// import { dataGlobalLeader } from "../../data/dataGlobalLeader";
// import { dataSecretary } from "../../data/dataGlobalLeader";
import PopUpMens from "../PopUpMens/PopUpMens";
import Loading from "../../helpers/Loading";
import useFetch from "../../hooks/useFetch";

function GlobalLeader() {
  // const [active, setActive] = useState(0);
  const [index, setIndex] = useState(0);
  // console.log('index :>> ', index);

  // Handle Tabs Sekunder
  const handleTab = (idx) => {
    setIndex(idx);
    // setActive(idx);
  };

  // SETUP POPUP
  const [popUp, setPopUp] = useState(null);
  // SETUP Secretary
  const [popSec, setPopSec] = useState(null);

  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/leader");
  const dataSecretary = useFetch("/api/secretary");
  // console.log("dataSecretary.response :>> ", dataSecretary.response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "Global Leadership",
      // desc: "Your account uses artificial intelligence to automatically categorize your spendings and show your app",
      desc: "",
      corpSecretary: "Corporate Secretary",
      data: response?.English,
      secretary: dataSecretary?.response?.English,
      pdfStruct: "Organization Structures",
    },
    Indonesia: {
      title: "Pimpinan Kami",
      desc: "",
      corpSecretary: "Sekretaris Perusahaan",
      data: response?.Indonesia,
      secretary: dataSecretary?.response?.Indonesia,
      pdfStruct: "Struktur Organisasi",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  // console.log("content.data :>> ", content.data);

  return (
    <div className="globalleader">
      <div className="globalleader__container">
        <div className="grafik__wrapper">
          <img src="/assets/grafic-leader.png" alt="" />
        </div>
        <div className="title">{content.title}</div>
        {/* <div className="desc">{content.desc}</div> */}

        {/* Tabs */}
        <div className="tabs">
          {content.data?.map((lead, idx) => (
            <button
              className={`tab ${index === idx && "active"}`}
              key={idx}
              onClick={() => handleTab(idx)}
            >
              {lead.title}
            </button>
          ))}
        </div>

        {/* Mens */}
        <div className="mens">
          {content.data &&
            content.data[index]?.mens.map((lead, idx) => (
              <div
                className="mens__container"
                key={idx}
                onClick={() => setPopUp(lead)}
              >
                <div className="image__wrapper">
                  <img src={lead.image} alt="" />
                </div>
                <div className="name">{lead.name}</div>
                <div className="jobs">{lead.jobs}</div>
              </div>
            ))}
          {/*  Pop UP */}
          {popSec && <PopUpMens popUp={popSec} setPopUp={setPopSec} />}
        </div>

        {/* Tabs 2 */}
        <div className="tabs">
          <button className="tab">
            <img src="/assets/pdfLeader.svg" alt="" />
            <a
              // href="/assets/Struktur Organisasi/Struktur Organisasi.pdf"
              href={content.data && content.data[index]?.pdf}
              target={"_blank"}
              rel="noreferrer"
            >
              <span>{content.pdfStruct}</span>
            </a>
          </button>
        </div>

        {/* Secretary */}
        <div className="title">{content.corpSecretary}</div>
        <div className="mens">
          {content.secretary &&
            content.secretary[0].mens?.map((sec, idx) => (
              <div
                className="mens__container"
                onClick={() => setPopSec(sec)}
                key={idx}
              >
                <div className="image__wrapper">
                  <img src={sec.image} alt="" />
                </div>
                <div className="name">{sec.name}</div>
                <div className="jobs">{sec.jobs}</div>
              </div>
            ))}
          {/* POP UP Secretary */}
          {popUp && <PopUpMens popUp={popUp} setPopUp={setPopUp} />}
        </div>

        {/* Tabs 3*/}
        <div className="tabs">
          <button className="tab">
            <img src="/assets/pdfLeader.svg" alt="" />
            <a
              // href="/assets/Sekretaris Perusahaan/Sekretaris_Perusahaan.pdf"
              href={content.secretary && content.secretary[index]?.pdf}
              target={"_blank"}
              rel="noreferrer"
            >
              <span>BBHI_Penggantian.pdf</span>
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

export default GlobalLeader;
