import { motion } from "framer-motion";
import "./membership.scss";

function Membership({ content }) {
  // console.log("content.imageMember :>> ", content.imageMember);
  return (
    <motion.div
      className="profiles__member"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <div className="profiles__member__container">
        <div className="member__image">
          {content?.imageMember && <img src={content.imageMember} alt="" />}
        </div>
        <div className="member__content">
          <div className="member__content__title">
            <span>{content?.titleMember}</span>
          </div>
          <div className="member__content__desc">
            <p>{content?.descMember}</p>
          </div>
          {content?.members?.map((item) => (
            <div className="member__items" key={item.id}>
              <div className="member__icon">
                <img src={item.icon} alt="" />
              </div>
              <div className="member__detail">
                <div className="title">{item.title}</div>
                <div className="description">{item.description}</div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </motion.div>
  );
}

export default Membership;
