import { useState } from "react";
import { useSelector } from "react-redux";

import "./pay.scss";

// import { dataPay } from "../../data/dataPay";
import HeroImage from "../../components/HeroImage/HeroImage";
import Features from "../../components/Features/Features";
import Registers from "../../components/Registers/Registers";
import GoToTop from "../../helpers/GoToTop";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function Pay() {
  const [active, setActive] = useState(0);
  const [index, setIndex] = useState(0);
  // Handle Tabs Sekunder
  const handleTab = (idx) => {
    setIndex(idx);
    setActive(idx);
  };

  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/services/pay");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="pay">
      <Seo
        title="Layanan Bayar | Allobank"
        description="Bayar dimana saja menggunakan QRIS jadi lebih mudah, cepat, dan aman."
      />
      <GoToTop />
      <HeroImage source={content.data} />
      {/* Features */}
      <Features dataFeatures={content.data} />
      {/* Registers */}
      <Registers
        dataRegisters={content.data}
        activeSekunder={active}
        handleTabSekunder={handleTab}
        indexSekunder={index}
      />
    </div>
  );
}

export default Pay;
