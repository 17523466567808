import "./detailpromo.scss";
import { motion } from "framer-motion";
import GoToTop from "../../helpers/GoToTop";
import { useParams } from "react-router-dom";
import { dataDetailPromotion } from "../../data/dataDetailPromotion";
import { useSelector } from "react-redux";
import { FacebookShareButton, TwitterShareButton } from "react-share";

function DetailPromo() {
  const params = useParams();
  console.log("params", params);

  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  //   SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: dataDetailPromotion.English,
    },
    Indonesia: {
      data: dataDetailPromotion.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  return (
    <motion.div
      className="detailpromo"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <GoToTop />
      <div className="detailpromo__container">
        <div className="container__other">
          <div className="detailpromo__title">{content.data.title}</div>
          <div className="image__wrapper">
            <img src="/assets/detailpromo.png" alt="" />
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data.description}` }}
          ></div>

          <div className="subtitle">Mechanism</div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data.mechanisme}` }}
          ></div>

          <div className="subtitle">Terms and Conditions</div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data.conditions}` }}
          ></div>

          <div className="share__icons">
            <div className="share">Share</div>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share1.svg" alt="" />
            </div>
            <FacebookShareButton url={`www.allobank.com/${params.slug}`}>
              <div className="img__wrapper">
                <img src="/assets/ikon-share/share2.svg" alt="" />
              </div>
            </FacebookShareButton>
            <TwitterShareButton url={`www.allobank.com/${params.slug}`}>
              <div className="img__wrapper">
                <img src="/assets/ikon-share/share3.svg" alt="" />
              </div>
            </TwitterShareButton>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share4.svg" alt="" />
            </div>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share5.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default DetailPromo;
