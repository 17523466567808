import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./inside.scss";
// import { dataInside } from "../../data/dataInside";

import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";

function Inside() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/inside/all");
  // console.log("response :>> ", response);

  // SETUP REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);

  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "What’s Inside Allo Bank?",
      learnMore: "Learn More",
      data: response?.English,
    },
    Indonesia: {
      title: "Jadi Mudah dengan Allo Bank",
      learnMore: "Cari Tahu",
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="inside">
      <div className="inside-container">
        <div className="title">{content.title}</div>
        <motion.div
          className="cards"
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 1,
          }}
          viewport={{ once: true }}
        >
          {content?.data?.map((inside) => (
            <div className="card-container" key={inside.id}>
              <img src={inside.image} alt={inside.title} />
              <div className="title-card">{inside.title}</div>
              {/* <div className="desc-card">{inside.desc}</div> */}
              <div className="desc-card">{inside.desc_type_produk}</div>
              <div className="learn-more">
                <Link to="/products">{content.learnMore} &rarr;</Link>
              </div>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default Inside;
