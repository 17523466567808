import { useRef, useState } from "react";
import { useEffect } from "react";
import CardPressRelease from "../CardPressRelease/CardPressRelease";

import "./press-release.scss";

import { ReactComponent as Prev } from "../../assets/prev.svg";

function PressRelease({ content }) {
  // console.log("content", content);

  // CONFIG SLIDER
  const listRef = useRef();
  const [slideNumber, setSlideNumber] = useState(0);
  const [clickLimit, setClickLimit] = useState(window.innerWidth / 425);

  useEffect(() => {
    if (window.innerWidth < 480) {
      setClickLimit(window.innerWidth / 358);
    }
  }, []);

  const handleClick = (direction) => {
    if (window.innerWidth < 480) {
      let distance = listRef.current.getBoundingClientRect().x - 20;

      if (direction === "next" && slideNumber === content.dataNews.length - 1) {
        setSlideNumber(0);
        listRef.current.style.transform = `translateX(0px)`;
      }

      if (
        direction === "next" &&
        slideNumber <= content.dataNews.length - clickLimit
      ) {
        setSlideNumber(slideNumber + 1);
        listRef.current.style.transform = `translateX(${-358 + distance}px)`;
      }

      if (direction === "prev" && slideNumber === 0) {
        listRef.current.style.transform = `translateX(0px)`;
      }

      if (direction === "prev" && slideNumber > 0) {
        setSlideNumber(slideNumber - 1);
        listRef.current.style.transform = `translateX(${358 + distance}px)`;
      }
    } else {
      let distance = listRef.current.getBoundingClientRect().x - 79;
      if (direction === "next" && slideNumber === content.dataNews.length - 3) {
        setSlideNumber(0);
        listRef.current.style.transform = `translateX(0px)`;
      }

      if (
        direction === "next" &&
        slideNumber <= content.dataNews.length - clickLimit
      ) {
        setSlideNumber(slideNumber + 1);
        listRef.current.style.transform = `translateX(${-425 + distance}px)`;
      }

      if (direction === "prev" && slideNumber === 0) {
        listRef.current.style.transform = `translateX(0px)`;
      }

      if (direction === "prev" && slideNumber > 0) {
        setSlideNumber(slideNumber - 1);
        listRef.current.style.transform = `translateX(${425 + distance}px)`;
      }
    }
  };
  // END CONFIG SLIDER

  return (
    <div className="press__release">
      <div className="press__release__title">{content.title}</div>
      <div className="press__release__cards">
        <div className="card__item" ref={listRef}>
          {/* Card */}
          {content.dataNews.length > 0 &&
            content.dataNews.map((data) => (
              <CardPressRelease key={data.id} dataRelease={data} />
            ))}
        </div>
      </div>
      <div className="card__buttons">
        <div className="button__wrapper">
          <div className="card__button" onClick={() => handleClick("prev")}>
            <Prev className="prev__button" />
          </div>
          <div className="card__button" onClick={() => handleClick("next")}>
            <Prev className="next__button" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PressRelease;
