import { useState } from "react";
import { useSelector } from "react-redux";

import "./products.scss";

import HeroImage from "../../components/HeroImage/HeroImage";
// import { heroImageProducts } from "../../data/heroImage";
// import { dataProducts } from "../../data/dataProducts";
import Tabs from "../../components/Tabs/Tabs";
import Features from "../../components/Features/Features";
import Tables from "../../components/Tables/Tables";
import Registers from "../../components/Registers/Registers";
import GoToTop from "../../helpers/GoToTop";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function Products() {
  const [indexUtama, setIndexUtama] = useState(0);
  const [indexSekunder, setIndexSekunder] = useState(0);
  const [active, setActive] = useState(0);
  const [activeSekunder, setActiveSekunder] = useState(0);
  // const [indexReg, setIndexReg] = useState(0);

  // Handle Tabs Utama
  const handleTab = (idx) => {
    setIndexUtama(idx);
    setActive(idx);
    setIndexSekunder(0);
  };

  // Handle Tabs Sekunder
  const handleTabSekunder = (idx) => {
    setIndexSekunder(idx);
    setActiveSekunder(idx);
  };

  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/produk/detail/all");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      // title: "Our Services",
      // learnMore: "Learn More",
      data: response?.English,
    },
    Indonesia: {
      // title: "Layanan Kami",
      // learnMore: "Cari Tahu",
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="products">
      <Seo
        title="Produk | Allobank"
        description="Berbagai kemudahan finansial langsung dari ponsel kamu. "
      />
      <GoToTop />
      <HeroImage source={content.data} />
      <div className="products__tabs">
        {/* Tabs */}
        <Tabs
          dataTab={content.data?.tabs}
          active={active}
          handleTab={handleTab}
        />

        {/* Features */}
        <Features dataFeatures={content.data?.tabs[indexUtama]} />

        {/* Tables */}
        <Tables dataTables={content.data?.tabs[indexUtama]?.tables} />
      </div>
      {/* Registers */}
      <Registers
        dataRegisters={content.data?.tabs[indexUtama]}
        activeSekunder={activeSekunder}
        handleTabSekunder={handleTabSekunder}
        indexSekunder={indexSekunder}
      />
    </div>
  );
}

export default Products;
