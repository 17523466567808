import "./heroimage.scss";
import { useWindow } from "../../helpers/useWindow";

function HeroImage({ source }) {
  // console.log("source", source);
  // Mobile
  const layer = useWindow();

  const color = (title) => {
    if (title === "About" || title === "Benefit") {
      return "white";
    }
    if (title === "Management") {
      return "yellow";
    }
  };

  return (
    <div className={`heroimage`}>
      <img
        src={layer >= 768 ? source?.image : source?.imageMobile}
        alt={source?.title}
      />
      {source?.title === "Products" ||
      source?.title === "Produk" ||
      source?.title === "Services" ||
      source?.title === "Service" ||
      source?.title === "Benefit" ||
      source?.title === "About" ? (
        <div className={`wrapper__title__banner ${color(source?.title)} `}>
          <div
            className={`title__bannerLeft ${
              source?.titleBanner === "All for One, One for All" && "full"
            }`}
          >
            {source?.titleBanner}
          </div>
          <div className="desc__bannerLeft">{source.descBanner}</div>
        </div>
      ) : (
        <div className={`title__banner ${color(source?.title)}`}>
          {source?.titleBanner}
        </div>
      )}
    </div>
  );
}

export default HeroImage;
