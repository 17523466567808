import { useSelector } from "react-redux";
import "./privacy.scss";

import GoToTop from "../../helpers/GoToTop";
// import { heroImagePrivacy } from "../../data/heroImage";
import HeroImage from "../../components/HeroImage/HeroImage";

// import { dataPrivacy } from "../../data/dataPrivacy";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function Privacy() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/privacy/all");
  // console.log("response :>> ", response);

  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  //   SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="privacy">
      <Seo
        title="Kebijakan Privasi | Allo Bank"
        description="PT Allo Bank Indonesia, Tbk melindungi setiap data yang tersimpan dalam sistem kami. Kebijakan privasi website akan selalu diperbaharui setiap waktu."
      />
      <GoToTop />
      <HeroImage source={content.data} />
      <div className="privacy__container">
        <div
          className="privacy__desc"
          dangerouslySetInnerHTML={{ __html: content.data?.desc }}
        ></div>
        <div
          className="privacy__detailDesc"
          dangerouslySetInnerHTML={{ __html: content.data?.detailDesc }}
        ></div>
        {/* <p>
          <b>Allo Bank</b> highly respects matters relating to the protection of
          Customer privacy. Therefore, <b>Allo Bank</b> compiled this Privacy
          Policy to explain to the Customer how Allo Bank obtains, collects,
          processes, analyzes, uses, stores, displays, announces, transmits,
          disseminates, deletes and destroys the Customer Personal Data provided
          to <b>Allo Bank</b> or otherwise collected by <b>Allo Bank</b> both
          when downloading, registering, accessing the website, and using
          services or products and the Allo App, including Loyalty Program. The
          Customer is required to read the <b>Allo App</b> Privacy Policy
          carefully to ensure that the Customer understands how the data
          processing process at Allo Bank is carried out. The terms in capital
          letters used have the same meaning as those contained in the
          <b> Allo App Terms and Conditions </b>listed on a different page.
        </p>
        <ol>
          <li>
            Personal Data
            <p>
              Personal Data is any data, information, and/or information in any
              form that can identify the Customer, which the Customer submits to
              Allo Bank from time to time or which the Customer includes or
              conveys in, on, and/or through the Application concerning the
              information regarding Customer personal, which includes, among
              others: full name, address, birth data, Customer identity card
              number, gender, electronic mail address (Email), mobile phone
              number (including but not limited to: IP address, location
              information, Customer device data, IMEI number, name of the
              application that has been attached to the Customer's device,
              information retrieval), data concerning the information regarding
              Customer's transaction activities using the Allo App, and other
              data classified as personal data.
            </p>
          </li>
          <li>Personal Data Accuracy</li>
          <li>Collection Of Personal Data</li>
          <li>Use Of Personal Data</li>
          <li>Clousure To Third Party Sites</li>
          <li>Links To Third Party Sites</li>
          <li>Data Storage and Security</li>
          <li>Unauthorized Access</li>
          <li>Intelectual Property Rights</li>
          <li>Access Requests and Changes of Personal Data</li>
          <li>Revocation Of Approval </li>
          <li>Applicable Law</li>
          <li>Changes To Privacy Policy</li>
          <li>Customer Statements and Approval</li>
        </ol> */}
      </div>
    </div>
  );
}

export default Privacy;
