import "./footer.scss";
import { medsos } from "../../data/dataMedsos";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

function Footer() {
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      contact: "Contact Us",
      rate: "Rate, Limit & Fees",
      news: "News & Events",
      disclaimer:
        "PT Allo Bank Indonesia, Tbk. is registered and supervised by Financial Services Authority (OJK), and also a participant of  Indonesia Deposit Insurance Corporation (LPS) guarantee program.",
      copyrightCenter: "Copyright @ Allo Bank 2021 All Right Reserved",
      copyrightRight: "Privacy Policy",
      term: "Terms & Conditions",
    },
    Indonesia: {
      contact: "Hubungi Kami",
      rate: "Bunga, Limit & Biaya",
      news: "Berita & Acara",
      disclaimer:
        "PT Allo Bank Indonesia, Tbk. terdaftar dan diawasi oleh Otoritas Jasa Keuangan (OJK), dan merupakan peserta program penjaminan Lembaga Penjamin Simpanan (LPS).",
      copyrightCenter: "Copyright 2021 PT Allo Bank Indonesia Tbk",
      copyrightRight: "Kebijakan Privasi",
      term: "Syarat & Ketentuan",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  return (
    <div className="footer">
      {/* <div className="subcribe">
        <div className="subcribe-wrapper">
          <h2>Download Allo Bank apps now!</h2>
          <div className="img-wrapper">
            <img src="/assets/app-store.png" alt="" />
            <img src="/assets/android-store.png" alt="" />
          </div>
        </div>
      </div> */}
      <div className="main-footer">
        <div className="main-footer-container">
          <div className="footer-navigation">
            <img src="/assets/logo-allo2.png" alt="" />
            <ul className="second-navigation">
              {/* <li>Career</li> */}
              <li>
                <Link to={"/news"}>{content.news}</Link>{" "}
                {/* <Link to={"/news-soon"}>{content.news}</Link>{" "} */}
              </li>

              <li>
                <Link to={"/rate-limit-fees"}>{content.rate}</Link>{" "}
              </li>
              <li>
                <Link to={"/help"}>{content.contact}</Link>{" "}
              </li>
            </ul>
          </div>
          {/* Medsos */}
          <div className="medsos">
            {medsos.map((logo, idx) => (
              <div className="wrapper-medsos" key={idx}>
                <a href={logo.link} target={"_blank"} rel="noreferrer">
                  <img src={logo.url} alt="" />
                </a>
              </div>
            ))}
          </div>
          {/* OJK */}
          <div className="ojk">
            <div className="container__image">
              <img src="/assets/ojk.png" alt="ojk" />
              <img src="/assets/lps.png" alt="lps" />
            </div>
            <div className="disclaimer">{content.disclaimer}</div>
          </div>
        </div>
      </div>
      <div className="copyright">
        <div className="copyright-container">
          <div className="copyright-center">{content.copyrightCenter}</div>
          <div className="copyright-right">
            <span>
              {/* <a
                href="/assets/Privacy Policy/Privacy Policy Allo Bank [English-Bahasa].docx"
                target={"_blank"}
                rel="noreferrer"
              >
                {content.copyrightRight}
              </a> */}
              <Link to="/privacy">{content.copyrightRight}</Link>
            </span>{" "}
            |{" "}
            <span>
              {/* <a
                href="/assets/Term and Conditions/1. T_C Allo Pay, Allo Pay plus, Loyalty system dan Allo App [English-Bahasa].docx"
                target={"_blank"}
                rel="noreferrer"
              >
                {content.term}
              </a> */}
              <Link to="/term-condition">{content.term}</Link>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
