export const dataDownload = {
  English: {
    descDownload:
      "Enjoy your one stop financial solution that will help you living simple and easy life",
    imageIos: "/assets/appstore-ENG-88.png",
    linkIos: "https://apps.apple.com/id/app/allobank/id1591223632",
    imageAndroid: "/assets/playstore-ENG-88.png",
    linkAndroid:
      "https://play.google.com/store/apps/details?id=com.alloapp.yump",
    imageQr: "/assets/QR.png",
  },
  Indonesia: {
    descDownload:
      "Nikmati fitur solusi finansial yang akan membantu kehidupan kamu lebih simple.",
    imageIos: "/assets/appstore-ID-88.png",
    linkIos: "https://apps.apple.com/id/app/allobank/id1591223632",
    imageAndroid: "/assets/playstore-ID-88.png",
    linkAndroid:
      "https://play.google.com/store/apps/details?id=com.alloapp.yump",
    imageQr: "/assets/QR.png",
  },
};
