import "./tabs.scss";

function Tabs({ dataTab, active, handleTab }) {
  return (
    <div className="products__tabs__container">
      {dataTab?.map((product, index) => (
        <button
          className={`products__tabs__container__tab  ${
            active === index && "active"
          }`}
          key={product.id}
          onClick={() => handleTab(index)}
        >
          {product.title}
        </button>
      ))}
    </div>
  );
}

export default Tabs;
