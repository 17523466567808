import { motion } from "framer-motion";
import { useSelector } from "react-redux";
// import useFetch from "../../hooks/useFetch";

import "./privilege.scss";

// import { dataPrivilege } from "../../data/dataPrivilege";
import Loading from "../../helpers/Loading";
import useFetch from "../../hooks/useFetch";

function Privilege() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/privilege/all");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "Allo Special Privilege for You",
      subTitle: "Special Privilege",
      data: response?.English,
    },
    Indonesia: {
      title: "Benefit Allo Bank",
      subTitle: "Benefit Kami",
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="privilege">
      <motion.div
        className="privilege-container"
        initial={{ y: 100 }}
        whileInView={{ y: 0 }}
        transition={{
          type: "spring",
          bounce: 0.2,
          duration: 1,
        }}
        viewport={{ once: true }}
      >
        <div className="privilege-subtitle">{content.subTitle}</div>
        <div className="privilege-title">{content.title}</div>
        <div className="privilege-cards">
          {content.data?.map((privilege) => (
            <div className="privilege-card" key={privilege.id}>
              <img src={privilege.image} alt={privilege.title} />
              <h4>{privilege.title}</h4>
              <p>{privilege.desc}</p>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default Privilege;
