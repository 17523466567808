export const dataLink = {
  English: [
    // {
    //   id: 1,
    //   root: "#",
    //   nav: "Products",
    //   list: [
    //     { url: "/products", title: "Allo  Pay and Allo Pay +" },
    //     { url: "/products", title: "Allo Prime" },
    //     { url: "/products", title: "Deposits" },
    //     { url: "/products", title: "Pay Later and Instant Cash" },
    //   ],
    // },
    {
      id: 1,
      root: "/products",
      nav: "Products",
      list: [],
    },
    {
      id: 2,
      root: "#",
      nav: "Services",
      list: [
        { url: "/services/pay", title: "Pay" },
        { url: "/services/top-up", title: "Top Up" },
        { url: "/services/transfer", title: "Transfer" },
        { url: "/services/bill-payment", title: "Bill Payment" },
        { url: "/services/cash-out", title: "Cash Out" },
        { url: "/services/send-gifts", title: "Send Gifts" },
      ],
    },
    {
      id: 3,
      root: "#",
      nav: "About Us",
      list: [
        { url: "/about-us/profiles", title: "Allo Bank Profiles" },
        {
          url: "/about-us/management-committee",
          title: "Management Committee",
        },
        { url: "/about-us/investor-relations", title: "Investor Relations" },
        // { url: "/about-us/corporate-governance", title: "Corporate Governance" },
        // { url: "/about-us/csr", title: "CSR" },
        // {
        //   url: "/about-us/article-of-association",
        //   title: "Article of Association",
        // },
      ],
    },
    { id: 4, root: "/benefit", nav: "Benefit", list: [] },
    // { id: 5, root: "/promo", nav: "Promo", list: [] },
    { id: 5, root: "/promo-soon", nav: "Promo", list: [] },
    { id: 6, root: "/help", nav: "Help", list: [] },
  ],
  Indonesia: [
    // {
    //   id: 1,
    //   root: "#",
    //   nav: "Produk",
    //   list: [
    //     { url: "/products", title: "Allo  Pay dan Allo Pay+" },
    //     { url: "/products", title: "Allo Prime" },
    //     { url: "/products", title: "Deposito" },
    //     { url: "/products", title: "Pay Later dan Instant Cash" },
    //   ],
    // },
    {
      id: 1,
      root: "/products",
      nav: "Produk",
      list: [],
    },
    {
      id: 2,
      root: "#",
      nav: "Layanan",
      list: [
        { url: "/services/pay", title: "Bayar" },
        { url: "/services/top-up", title: "Top Up" },
        { url: "/services/transfer", title: "Transfer" },
        { url: "/services/bill-payment", title: "Bayar Tagihan" },
        { url: "/services/cash-out", title: "Tarik Tunai" },
        { url: "/services/send-gifts", title: "Kirim Hadiah" },
      ],
    },
    {
      id: 3,
      root: "#",
      nav: "Tentang Kami",
      list: [
        { url: "/about-us/profiles", title: "Profil Allo Bank" },
        {
          url: "/about-us/management-committee",
          title: "Manajemen Komite",
        },
        { url: "/about-us/investor-relations", title: "Hubungan Investor" },
        // { url: "/about-us/corporate-governance", title: "Tata Kelola Perusahaan" },
        // { url: "/about-us/csr", title: "CSR" },
        // {
        //   url: "/about-us/article-of-association",
        //   title: "Berita dan Acara",
        // },
      ],
    },
    { id: 4, root: "/benefit", nav: "Benefit", list: [] },
    // { id: 5, root: "/promo", nav: "Promo", list: [] },
    { id: 5, root: "/promo-soon", nav: "Promo", list: [] },
    { id: 6, root: "/help", nav: "Bantuan", list: [] },
  ],
};
