import "./faq.scss";
import { motion } from "framer-motion";
import { useState } from "react";

function Faq({ dataFeatures }) {
  const [open, setOpen] = useState(true);
  const [index, setIndex] = useState(0);

  return (
    <div className="faq__tabs__contents">
      <motion.div
        className="faq__tabs__contents__cards"
        initial={{ y: 100 }}
        whileInView={{ y: 0 }}
        transition={{
          type: "spring",
          bounce: 0.2,
          duration: 0.3,
        }}
        viewport={{ once: true }}
      >
        {dataFeatures?.features.map((feature, idx) => (
          <div className="cards" key={idx}>
            <div
              className={`cards__title ${index === idx && "active"}`}
              onClick={() => {
                setOpen(!open);
                setIndex(idx);
              }}
            >
              <span>{feature.featureTitle}</span>{" "}
              {open && index === idx ? (
                <img src="/assets/arrowdownright.svg" alt="" />
              ) : (
                <img src="/assets/arrowright.svg" alt="" />
              )}
            </div>
            {open && index === idx && (
              <div
                className="cards__desc"
                dangerouslySetInnerHTML={{ __html: feature.featureDesc }}
              ></div>
            )}
          </div>
        ))}
      </motion.div>
    </div>
  );
}

export default Faq;
