import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./slider.scss";

// import { dataSlides } from "../../data/dataSlides";
import { useWindow } from "../../helpers/useWindow";
import Loading from "../../helpers/Loading";
import useFetch from "../../hooks/useFetch";
// import axios from "axios";

function Slider() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/banner/all/home");

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: response?.English,
    },
    Indonesia: {
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // CONFIG SLIDER
  const layer = useWindow();

  const [slideIdx, setSlideIdx] = useState(1);

  const currentSlide = (index) => {
    // console.log("index :>> ", index);
    showSlides(setSlideIdx(index));
  };

  const showSlides = useCallback(
    (index) => {
      const slides = document.getElementsByClassName("mySlides");
      const dots = document.getElementsByClassName("dot");

      if (index > slides.length) {
        // slideIndex = 1;
        setSlideIdx(1);
      }
      if (index < 1) {
        // slideIndex = slides.length;
        setSlideIdx(slides.length);
      }

      for (let i = 0; i < slides.length; i++) {
        slides[i].style.display = "none";
      }

      for (let i = 0; i < dots.length; i++) {
        dots[i].className = dots[i].className.replace(" active", "");
      }

      if (content.data) {
        slides[slideIdx - 1].style.display = "block";
        dots[slideIdx - 1].className += " active";
      }
    },
    [slideIdx, content.data]
  );

  useEffect(() => {
    showSlides(slideIdx);

    // Auto play
    const timer = setTimeout(() => {
      if (slideIdx < content.data?.length) {
        setSlideIdx(slideIdx + 1);
      } else {
        setSlideIdx(1);
      }
    }, 7000);

    return () => {
      clearTimeout(timer);
    };
  }, [slideIdx, showSlides, content.data]);

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="slideshow-container">
      {content.data &&
        content.data.map((item) => (
          <div className="mySlides fade" key={item.id}>
            <a href={item?.link} target="_blank" rel="noreferrer">
              <img
                src={layer >= 768 ? item.image : item.imageMobile}
                alt={item.title}
              />
            </a>

            <div className="slides-container">
              {/* <h2>{item.title}</h2> */}
              {/* <p>
              Enjoy your one stop financial solution that will help you living
              simple and easy life
            </p> */}
              <div className="wrapper-images ">
                {item?.button &&
                  item.button.map((btn, idx) => (
                    <a
                      href={btn.link_button}
                      key={idx}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={btn.image_button} alt="" />
                    </a>
                  ))}
              </div>
            </div>
          </div>
        ))}

      {/* The Dots */}
      <div className="dots">
        {content.data &&
          content.data.map((item, idx) => (
            <span
              className="dot"
              onClick={() => currentSlide(idx + 1)}
              key={idx}
            ></span>
          ))}
      </div>
    </div>
  );
}

export default Slider;
