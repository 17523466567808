import { useSelector } from "react-redux";
import "./downloadnow.scss";
import { dataDownload } from "../../data/dataDownload";

function DownloadNow() {
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: dataDownload?.English,
    },
    Indonesia: {
      data: dataDownload?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  return (
    <div className="downloadnow">
      <div className="downloadnow__container">
        <div className="decoration">
          <img src="/assets/supergraphics background.png" alt="" />
        </div>
        <div className="contents">
          <div className="wrapper__image">
            <img src="/assets/logo-allo.png" alt="" />
          </div>
          <div className="sub__heading">{content?.data?.descDownload}</div>
          <div className="group__images">
            <div className="wrapper__image__group">
              <a href={content?.data?.linkIos} target="_blank" rel="noreferrer">
                <img src={content?.data?.imageIos} alt="" />
              </a>
            </div>
            <div className="wrapper__image__group">
              <a
                href={content?.data?.linkAndroid}
                target="_blank"
                rel="noreferrer"
              >
                <img src={content?.data?.imageAndroid} alt="" />
              </a>
            </div>
          </div>
          <div className="wrapper__image__qr">
            <img src={content?.data?.imageQr} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default DownloadNow;
