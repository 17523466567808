import { useSelector } from "react-redux";

import "./ourvalues.scss";
// import { IconValues } from "../../data/dataValues";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";

function OurValues() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/iconvalue");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "Our Values",
      data: response?.English,
    },
    Indonesia: {
      title: "Nilai-nilai",
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="ourvalues">
      <div className="ourvalues__container">
        <div className="title">{content.title}</div>
        <div className="icons">
          {content.data?.map((icon, i) => (
            <div className="icon" key={i}>
              <div className="wrapper__img">
                <img src={icon.image} alt="" />
              </div>
              <div className="title__icon">{icon.title}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default OurValues;
