import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import "./detailnews.scss";
import { motion } from "framer-motion";
import GoToTop from "../../helpers/GoToTop";
// import { dataDetailNews } from "../../data/dataDetailNews";
import { FacebookShareButton, TwitterShareButton } from "react-share";
import { Axios } from "../../config/axios";
import Loading from "../../helpers/Loading";
import Seo from "../../helpers/Seo";

function DetailNews() {
  const params = useParams();
  // console.log("params", params);
  // GET API
  const [dataDetail, setDataDetail] = useState(null);

  useEffect(() => {
    const fetchDetail = async () => {
      const response = await Axios.get(`/api/detail/${params.slug}`);
      setDataDetail(response.data);
    };

    fetchDetail();

    // Cleanup
    return () => {
      setDataDetail(null);
    };
  }, [params.slug]);

  //   SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  //   SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: dataDetail?.English,
    },
    Indonesia: {
      data: dataDetail?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  if (!dataDetail) {
    return (
      <div className="detailnews">
        <Loading />
      </div>
    );
  }

  return (
    <motion.div
      className="detailnews"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 1,
      }}
      viewport={{ once: true }}
    >
      <Seo
        title={content.data?.title.substring(0, 60)}
        description={content.data?.description.substring(0, 160)}
      />

      <GoToTop />
      <div className="detailnews__container">
        <div className="container__other">
          <div className="detailnews__title">{content.data?.title}</div>
          {/* {content.data?.image !== "-" && (
            <div className="image__wrapper">
              <img src={content.data?.image} alt="" />
            </div>
          )} */}
          <div className="detailnews__datePublish">
            {content.data?.tanggal_publish}
          </div>
          <div
            dangerouslySetInnerHTML={{ __html: `${content.data?.description}` }}
          ></div>

          <div className="share__icons">
            <div className="share">Share</div>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share1.svg" alt="" />
            </div>
            <FacebookShareButton url={`www.allobank.com/${params.slug}`}>
              <div className="img__wrapper">
                <img src="/assets/ikon-share/share2.svg" alt="" />
              </div>
            </FacebookShareButton>
            <TwitterShareButton url={`www.allobank.com/${params.slug}`}>
              <div className="img__wrapper">
                <img src="/assets/ikon-share/share3.svg" alt="" />
              </div>
            </TwitterShareButton>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share4.svg" alt="" />
            </div>
            <div className="img__wrapper">
              <img src="/assets/ikon-share/share5.svg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default DetailNews;
