import { motion } from "framer-motion";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

import "./ourservices.scss";

// import { dataServices } from "../../data/dataServices";
import useFetch from "../../hooks/useFetch";
import Loading from "../../helpers/Loading";

function OurServices() {
  // GET API WITH CUSTOM HOOKS
  const { response, loading } = useFetch("/api/service/all");
  // console.log("response :>> ", response);

  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      title: "Our Services",
      learnMore: "Learn More",
      data: response?.English,
    },
    Indonesia: {
      title: "Layanan Kami",
      learnMore: "Cari Tahu",
      data: response?.Indonesia,
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  // Loading sebelum data dari API masuk
  if (!response && loading) {
    return <Loading />;
  }

  return (
    <div className="ourservices">
      <motion.div
        className="ourservices-container"
        initial={{ y: 200 }}
        whileInView={{ y: 0 }}
        transition={{
          type: "spring",
          bounce: 0.2,
          duration: 1,
        }}
        viewport={{ once: true }}
      >
        <div className="ourservices-title">{content.title}</div>
        <div className="ourservices-cards">
          {content?.data?.map((service) => (
            <div className="card-container" key={service.id}>
              <img src={service.image} alt={service.title} />
              <div className="title-card">{service.title}</div>
              <div className="desc-card">{service.desc}</div>
              <div className="learn-more">
                <Link to={`/services/${service.url}`}>
                  {content.learnMore} &rarr;
                </Link>
              </div>
            </div>
          ))}
        </div>
      </motion.div>
    </div>
  );
}

export default OurServices;
