import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import "./vision.scss";

function Vision({ data }) {
  // console.log("data :>> ", data);
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      titleVision: "Vision",
      titleMission: "Mission",
      // descVision:
      //   "Become to the best Digital Banking through ALL in One Application by utilizing the ecosystem to provide greatest added value for the nation",
      // descMission:
      //   "Providing innovative digital banking products and services integrated with an ecosystem that provides seamless customer experience for customer and give high added value to all stakeholders",
    },
    Indonesia: {
      titleVision: "Visi",
      titleMission: "Misi",
      // descVision:
      //   "Menjadi Bank Digital terbaik melalui aplikasi ALL in One dengan memanfaatkan ekosistem untuk memberikan nilai tambah yang sebesar-besarnya bagi bangsa.",
      // descMission:
      //   "Menyediakan produk dan layanan perbankan digital inovatif terintegrasi dengan ekosistem yang memberikan solusi dan pengalaman nasabah yang mulus atau disebut juga seamless customer experience bagi nasabah serta memberikan nilai tambah yang tinggi kepada seluruh pemangku kepentingan.",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  return (
    <motion.div
      className="vision"
      initial={{ y: 200 }}
      whileInView={{ y: 0 }}
      transition={{
        type: "spring",
        bounce: 0.2,
        duration: 0.3,
      }}
      viewport={{ once: true }}
    >
      <div className="vision__container">
        <div className="vision__wrapper first__wrapper">
          <div className="vision__title">{content.titleVision}</div>
          <div className="vision__desc">{data?.visi}</div>
        </div>
        <div className="vision__wrapper">
          <div className="vision__title">{content.titleMission}</div>
          <div className="vision__desc">{data?.misi}</div>
        </div>
      </div>
    </motion.div>
  );
}

export default Vision;
