export const dataDetailPromotion = {
  English: {
    id: 1,
    slug: "make-the-most-of-allo-paylater-get-10-5-or-1-cashback",
    image: "/assets/promo1.png",
    title: "BLACK FRIDAY SALE - Discount up to 60% off",
    description: `
    <p>
      And they’re not all from family or friends. An increasing amount of
      texts are being sent by businesses. High open and click-through
      rates make SMS the ideal channel for reaching customers. Plus,
      customers have to opt-in first. This means if you send marketing
      texts, you can be sure the recipients are actually interested.
    </p>
    <p>
      So that answers why you should send texts, but how about what you
      should send? After nearly a decade working with clients across a
      variety of industries, we’ve come to learn what works and what
      doesn’t.
    </p>
    `,
    mechanisme: `
    <ol>
    <li>Promo period: 2 July 2021 - 25 August 2021</li>
    <li>Refer your friends to join Jago</li>
    <li>
      Get cash reward of IDR25,000 for you and your friend and stand a
      chance to win up to IDR20,000,000 in our raffle
    </li>
    <li>Requirements:</li>
      </ol>
      <ul>
        <li>
          Enter your mobile number registered on your Jago account on the
          Referral website
        </li>
        <li>
          Sample message with your unique referral link will be generated
          (please refrain from changing you mobile number on Jago during the
          promo period as that will prevent accruing your referrals to your
          unique link and you)
        </li>
        <li>
          Copy and send message to your friends Your friends (referees) will
          be directed to the website and will be prompted to enter their
          name, email address and mobile number they intend to use for
          registration of their Jago account
        </li>
        <li>
          Referees will be directed to download the Jago app and reminded to
          use the email address and mobile number submitted to register on
          Jago
        </li>
        <li>
          For both you and your referee to be eligible for the incentive,
          top up your Jago account for a minimum balance of IDR746,000 by 25
          August 2021
        </li>
        <li>
          For you to be eligible in the raffle, ensure that you have at
          least 1 complete referral - both you and your referee must have
          min balance of Rp 746K by 25 August 2021
        </li>
      </ul>
    `,
    conditions: `
    <ol>
      <li>Ensure you have a registered account with Jago.</li>
      <li>
        Create your unique link with the same mobile number on your Jago
        account.
      </li>
      <li>
        Refrain from changing your mobile number on Jago within the month
        of the promo as that will prevent accruing your referrals to your
        unique link and you.
      </li>
      <li>Use your unique referral link to refer your friends.</li>
      <li>
        Your friend needs to input their name, email addresses and mobile
        number on the website by clicking on your unique link.
      </li>
      <li>
        Your friend needs to download and register for a Jago account by
        using the same email address and mobile number submitted for
        referral to be accrued to you.
      </li>
      <li>
        A referral is considered complete and eligible for IDR25,000
        incentive if your friend creates an account accrued to you and you
        and your referee each has min IDR746,000 balance in your Jago
        accounts by 25 August 2021
      </li>
    </ol>
    `,
  },
  Indonesia: {
    id: 1,
    slug: "make-the-most-of-allo-paylater-get-10-5-or-1-cashback",
    image: "/assets/promo1.png",
    title: "BLACK FRIDAY SALE - Diskon sampai dengan 60%",
    description: `
    <p>
      And they’re not all from family or friends. An increasing amount of
      texts are being sent by businesses. High open and click-through
      rates make SMS the ideal channel for reaching customers. Plus,
      customers have to opt-in first. This means if you send marketing
      texts, you can be sure the recipients are actually interested.
    </p>
    <p>
      So that answers why you should send texts, but how about what you
      should send? After nearly a decade working with clients across a
      variety of industries, we’ve come to learn what works and what
      doesn’t.
    </p>
    `,
    mechanisme: `
    <ol>
    <li>Promo period: 2 July 2021 - 25 August 2021</li>
    <li>Refer your friends to join Jago</li>
    <li>
      Get cash reward of IDR25,000 for you and your friend and stand a
      chance to win up to IDR20,000,000 in our raffle
    </li>
    <li>Requirements:</li>
    </ol>
    <ul>
      <li>
        Enter your mobile number registered on your Jago account on the
        Referral website
      </li>
      <li>
        Sample message with your unique referral link will be generated
        (please refrain from changing you mobile number on Jago during the
        promo period as that will prevent accruing your referrals to your
        unique link and you)
      </li>
      <li>
        Copy and send message to your friends Your friends (referees) will
        be directed to the website and will be prompted to enter their
        name, email address and mobile number they intend to use for
        registration of their Jago account
      </li>
      <li>
        Referees will be directed to download the Jago app and reminded to
        use the email address and mobile number submitted to register on
        Jago
      </li>
      <li>
        For both you and your referee to be eligible for the incentive,
        top up your Jago account for a minimum balance of IDR746,000 by 25
        August 2021
      </li>
      <li>
        For you to be eligible in the raffle, ensure that you have at
        least 1 complete referral - both you and your referee must have
        min balance of Rp 746K by 25 August 2021
      </li>
    </ul>
    `,
    conditions: `
    <ol>
      <li>Ensure you have a registered account with Jago.</li>
      <li>
        Create your unique link with the same mobile number on your Jago
        account.
      </li>
      <li>
        Refrain from changing your mobile number on Jago within the month
        of the promo as that will prevent accruing your referrals to your
        unique link and you.
      </li>
      <li>Use your unique referral link to refer your friends.</li>
      <li>
        Your friend needs to input their name, email addresses and mobile
        number on the website by clicking on your unique link.
      </li>
      <li>
        Your friend needs to download and register for a Jago account by
        using the same email address and mobile number submitted for
        referral to be accrued to you.
      </li>
      <li>
        A referral is considered complete and eligible for IDR25,000
        incentive if your friend creates an account accrued to you and you
        and your referee each has min IDR746,000 balance in your Jago
        accounts by 25 August 2021
      </li>
    </ol>
    `,
  },
};
