// import { useSelector } from "react-redux";
import { motion } from "framer-motion";

import "./iconsbillpayment.scss";

// import { dataIconBill } from "../../data/dataIconBill";

function IconsBillPayment({ data, title }) {
  // SET REDUX
  // const state = useSelector((state) => state.translateReducer.bahasa);
  // SETUP CONTENTS BASED ON LANGUAGE
  // let content = {
  //   English: {
  //     title: "Simplify the way you pay with Allo Bank.",
  //     // data: dataIconBill?.English,
  //   },
  //   Indonesia: {
  //     title: "Apapun tagihan kamu bayarnya lewat Allo Bank",
  //     // data: dataIconBill?.Indonesia,
  //   },
  // };

  // if (state === "Indonesia") {
  //   content = content.Indonesia;
  // } else {
  //   content = content.English;
  // }

  return (
    <div className="iconsbillpayment">
      <div className="iconsbillpayment__container">
        <div className="billpayment__title">{title}</div>
        <motion.div
          className="icons__container"
          initial={{ y: 200 }}
          whileInView={{ y: 0 }}
          transition={{
            type: "spring",
            bounce: 0.2,
            duration: 0.3,
          }}
          viewport={{ once: true }}
        >
          {data?.map((icon, i) => (
            <div className="image__wrapper" key={i}>
              <img src={icon.image} alt={icon.title} />
              <div className="image__wrapper__title">{icon.title}</div>
            </div>
          ))}
        </motion.div>
      </div>
    </div>
  );
}

export default IconsBillPayment;
