import { useState } from "react";
import { useEffect } from "react";
import { Link, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";

import "./navitem.scss";
import { ReactComponent as IconNav } from "../../assets/icon-nav.svg";
import { ReactComponent as Globe } from "../../assets/globe.svg";
import { dataLink } from "../../data/dataLink";
import { translateAction } from "../../store/actions/translateAction";

function NavItem({ variant, setIsOpen }) {
  // SET REDUX
  const state = useSelector((state) => state.translateReducer.bahasa);
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [item, setItem] = useState(1);

  // handleClick
  let timer;
  const handleClick = (id) => {
    // console.log("id :>> ", id);

    if (id === 1 || id === 4 || id === 5 || id === 6) {
      setIsOpen(false); // tutup mobile
    }

    setOpen(true); // buka submenu
    // console.log("id :>> ", id);
    // modalRef.current.style.animation = "slideOut 1s";
    setItem(id);

    timer = setTimeout(() => {
      setOpen(false);
    }, 5000);
  };

  useEffect(() => {
    return () => {
      clearTimeout(timer);
    };
  }, [timer]);

  // handle Submenu
  // const clickSubmenu = () => {};

  // ADD Class In Mobile
  let typeClass = "";

  if (variant === "mobile") {
    typeClass = "mobile";
  }

  // SETUP CONTENTS BASED ON LANGUAGE
  let content = {
    English: {
      data: dataLink.English,
      download: "Download Now",
    },
    Indonesia: {
      data: dataLink.Indonesia,
      download: "Download Sekarang",
    },
  };

  if (state === "Indonesia") {
    content = content.Indonesia;
  } else {
    content = content.English;
  }

  return (
    <>
      {content?.data &&
        content.data?.map((el) => (
          <li
            className={typeClass}
            key={el.id}
            // onMouseEnter={() => handleClick(el.id)}
            // onMouseLeave={() => setOpen(false)}
          >
            <NavLink
              to={el.root}
              className={el.root === "#" ? "decoration__none" : ""}
            >
              <span onClick={() => handleClick(el.id)}>
                {el.nav}{" "}
                {el.list.length > 0 && (
                  <IconNav
                    // onClick={() => handleClick(el.id)}
                    className="arrow"
                  />
                )}
              </span>
            </NavLink>

            {open && el.id === item && el.list.length > 0 && (
              <>
                <div className="curve"></div>
                <div className="items">
                  {el.list.map((i, idx) => (
                    <div
                      className="item"
                      key={idx}
                      onClick={() => setOpen(false)}
                    >
                      <NavLink to={i.url}>
                        <span onClick={() => setIsOpen(false)}>{i.title}</span>
                      </NavLink>
                    </div>
                  ))}
                </div>
              </>
            )}
          </li>
        ))}
      {state === "Indonesia" ? (
        <li
          className={`translate ${typeClass}`}
          onClick={() => {
            dispatch(translateAction("English"));
            setIsOpen(false);
          }}
        >
          <span className="id">ID</span>
          <Globe className="en" />
        </li>
      ) : (
        <li
          className={`translate ${typeClass}`}
          onClick={() => {
            dispatch(translateAction("Indonesia"));
            setIsOpen(false);
          }}
        >
          <Globe className="id" />
          <span className="en">EN</span>
        </li>
      )}
      <li className={`download ${typeClass}`} onClick={() => setIsOpen(false)}>
        {" "}
        <Link to={"/download"}>{content.download}</Link>{" "}
      </li>
    </>
  );
}

export default NavItem;
