import { useState } from "react";
import { NavLink } from "react-router-dom";
import MenuMobile from "../MenuMobile/MenuMobile";
import NavItems from "../NavItems/NavItems";
import "./header.scss";

function Header() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="header">
      <div className="header-container">
        <div className="header-logo">
          <NavLink to="/">
            <img src="/assets/logo-allo.png" alt="Logo Allo" />
          </NavLink>
        </div>
        <div className="header-nav">
          <NavItems setIsOpen={setIsOpen} />
          {/* Hamburger menu */}
          <MenuMobile setIsOpen={setIsOpen} isOpen={isOpen} />
        </div>
      </div>
    </div>
  );
}

export default Header;
